import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroTeam from "../components/heroTeam";
import Team from "../components/team";
import SubfooterTeam from "../components/subfooterteam";

function TeamPage({ location, data }) {
  const content = data.markdownRemark.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      <Team
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        team={content.people}
      />
      <SubfooterTeam />
    </Layout>
  );
}

export const teamPageQuery = graphql`
  query TeamTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      html
      frontmatter {
        hero {
          heroText
        }
        values {
          value {
            title
            content
          }
        }
        people {
          person {
            name
            title
            linkedin
            image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TeamPage;
