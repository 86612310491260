import React, { useState } from "react";
import Block from "./block";
import BloomGreen from "../../static/img/bloom-green-bg.svg";
import Person from "./person";

function Team({ team }) {
  return (
    <Block bgImage={BloomGreen} className={`bloom-bg-white`}>
      <div className={`bloom-flex bloom-flex-wrap bloom--mx-2`}>
        {team.person.map((person, index, linkedin) => {
          return (
            <Person
              count={index}
              key={index}
              linkedin={linkedin}
              person={person}
            />
          );
        })}
      </div>
    </Block>
  );
}

export default Team;
