import React from "react";
import Block from "./block";
import { Link } from "gatsby";

function Subfooter() {
  return (
    <div>
      <Block className={`bloom-bg-grey-light`}>
        <div className="bloom-flex">
          <p
            className={`bloom-text-2xl bloom-text-grey-darkest bloom-font-bold bloom-mb-5`}
          >
            Interested in joining the team? Check out our <Link className={`bloom-text-2xl bloom-font-bold bloom-text-green bloom-underline`} to="/jobs"> Jobs Page</Link>.
          </p>
        </div>
      </Block>

      <Block className={`bloom-bg-green`} id="contact">
        <div className="bloom-flex">
          <h2
            className={`bloom-text-4xl bloom-uppercase bloom-text-white bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-white`}
          >
            Get in touch
          </h2>
        </div>
        <div className="bloom-flex bloom-flex-wrap bloom--mx-4">
          <div className="bloom-w-full bloom-px-4">
            <div className="bloom-text-white bloom-leading-tight bloom-mb-2">
              <strong>Let us know how we can help:</strong>
            </div>
            <div className="bloom-text-white">
              <a
                className="bloom-border-white bloom-border-b hover:bloom-text-black-full hover:bloom-border-black-full"
                href="mailto:hello@bloomworks.digital"
              >
                hello@bloomworks.digital
              </a>
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
}

export default Subfooter;
