import React from "react";
import Img from "gatsby-image";
import LinkedIn from "./linkedin";

function Person({ person }) {
  return (
    <div
      className={`bloom-w-full sm:bloom-w-1/2 lg:bloom-w-1/3 bloom-mb-12 bloom-px-2`}
    >
      <div className={`bloom-mb-4`}>
        <Img className={`w-full`} fluid={person.image.childImageSharp.fluid} />
      </div>
      <h3
        className={`bloom-leading-tight bloom-text-2xl bloom-font-bold bloom-uppercase bloom-border-t-4 bloom-border-green`}
      >
        {person.name}
      </h3>
      <div className={`bloom-leading-tight bloom-text-xl`}>{person.title}</div>
      {person.linkedin && (
        <a
          href={`${person.linkedin}`}
          title={`${person.name}'s LinkedIn Profile`}
        >
          <LinkedIn />
        </a>
      )}
    </div>
  );
}

export default Person;
